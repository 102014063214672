import request from '@/utils/request'

// 服务周期选项数据
export function findBaseCarrierProductServiceCycleOptions() {
  return [
    { label: '1个自然月', value: 1 },
    { label: '3个自然月(季)', value: 3 },
    { label: '6个自然月(半年)', value: 6 },
    { label: '12个自然月(年)', value: 12 }
  ]
}

// 查询运营商通用套餐
export function findBaseCarrierProducts(params) {
  return request({
    url: `/base_carrier_products`,
    method: 'get',
    params: params
  })
}

// 创建运营商通用套餐
export function createBaseCarrierProduct(data) {
  return request({
    url: `/base_carrier_products`,
    method: 'post',
    data
  })
}

// 查询单个运营商通用套餐表单值
export function findBaseCarrierProductForm(id) {
  return request({
    url: `/base_carrier_products/${id}/form`,
    method: 'get'
  })
}

// 更新运营商通用套餐
export function updateBaseCarrierProduct(id, data) {
  return request({
    url: `/base_carrier_products/${id}`,
    method: 'put',
    data
  })
}

// 批量更新运营商通用套餐有效性
export function batchUpdateBaseCarrierProductsEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/base_carrier_products/effective/batch`,
    method: 'patch',
    data
  })
}

// 查询可导入的运营商通用套餐
export function findImportableBaseCarrierProducts(params) {
  return request({
    url: `/base_carrier_products/importable`,
    method: 'get',
    params: params
  })
}

// 查询可导入的运营商通用套餐
export function exportBaseCarrierProducts(params) {
  return request({
    url: `/base_carrier_products/export`,
    method: 'post',
    params: params
  })
}
